<template>
  <div id="home">
    <div class="who-i-am">
      <div class="who-i-am-container">
        <div class="who-picture">
          <div class="who-picture-container">
            <div class="picture-container">
              <img src="../assets/images/who-picture.jpg" alt="Who picture">
            </div>
          </div>
        </div>
        <div class="who-text">
          <h3>Alex RAYER</h3>
          <p>
            Photographe de paysages, j'affectionne particulièrement les lieux escarpés et dépeuplés, ces lieux où l'on
            se sent petit et on ressent la puissance de la nature. <br/>
            <br/>
            Ayant choisi de porter cette passion à l'avant de ma vie professionnelle et d'étudier la photographie, mes
            compétences s'étendent au-delà de la photographie de paysages, en passant par le studio ou la réalisation de
            courtes vidéos.
          </p>
        </div>
      </div>
    </div>
    <div class="parallax-container">
      <div class="parallax">
        <img class="parallax-image" src="../assets/images/parallax.jpg" alt="Parallax image"/>
      </div>
      <div class="parallax-text">
        <span>Une photographie n'est pas qu'une image, c'est une émotion, un ressenti particulier.</span>
      </div>
    </div>
    <div class="gallery-container">
      <div class="gallery-grid">
        <div class="picture-container" v-for="picture in galleryPictures">
          <img alt="Gallery picture" :src="'/' + picture.path">
        </div>
      </div>
    </div>
    <div class="carousel">
      <img src="../assets/images/home_picture.jpg" alt="Home picture">
      <span class="text-video">Balade dans le <br/>Queyras</span>
    </div>
  </div>
</template>

<script>
import http from "@/http/config";

export default {
  name: "Home",
  data() {
    return {
      parallaxElement: null,
      galleryPictures: [],
      galleryRows: 4
    };
  },
  methods: {
    enableParallax() {
      this.parallaxElement = document.querySelector("img.parallax-image");
      this.parallaxElement.style.opacity = 1;
      window.addEventListener("scroll", this.parallaxScrollEvent);
    },
    parallaxScrollEvent() {
      let parallaxContainer = document.querySelector("div.parallax-container");
      let containerHeight = parallaxContainer.getBoundingClientRect().height;
      let imgHeight = this.parallaxElement.offsetHeight;
      let parallaxDist = imgHeight - containerHeight;
      let top = parallaxContainer.getBoundingClientRect().top + window.pageYOffset - document.documentElement.clientTop;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      let windowHeight = window.innerHeight;

      if (top + containerHeight > scrollTop && top < scrollTop + windowHeight) {
        let windowBottom = scrollTop + windowHeight;
        let percentScrolled = (windowBottom - top) / (containerHeight + windowHeight);
        this.parallaxElement.style.transform = `translate3D(-50%, ${parallaxDist * percentScrolled}px, 0)`;
      }
    },
    retrieveGalleryPicture() {
      http.get("/portfolio/pictures/random").then(value => {
        this.galleryPictures = value.data;
      });
    }
  },
  created() {
    this.retrieveGalleryPicture();
  },
  mounted() {
    this.enableParallax();
  },
  unmounted() {
    window.removeEventListener("scroll", this.parallaxScrollEvent);
  }
}
</script>

<style scoped lang="less">
@width: 80%;

@media all and (max-width: 800px) {
  div.carousel {
    width: 100% !important;
    height: 300px !important;

    span.text-video {
      left: 50% !important;
      width: calc(95% - (30px * 2));
      font-size: 2.3em !important;
      transform: translateX(-50%);
    }
  }

  div.who-i-am {
    width: 90% !important;

    & div.who-i-am-container {
      flex-direction: column;

      & div.who-picture {
        margin-right: 0;
      }

      & div.who-text p {
        padding-left: 0;
      }
    }
  }

  div.parallax-container {
    width: 100% !important;
  }
}

div.carousel {
  position: relative;
  width: @width;
  height: 500px;
  margin: auto;
  background-color: white;
  overflow: hidden;

  & video, & img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & span.text-video {
    position: absolute;
    top: 40px;
    left: 70px;
    padding: 15px 30px;
    font-size: 3.5em;
    font-weight: 300;
    background-color: rgba(230, 230, 230, .6);
  }
}

div.who-i-am {
  display: flex;
  margin: 0 auto 50px auto;
  width: @width;

  & div.who-i-am-container {
    position: relative;
    display: flex;
    margin: auto;
    max-width: 1600px;

    & div.who-picture {
      position: relative;
      display: flex;
      margin-right: 20px;

      & div.who-picture-container {
        position: relative;
        margin: auto;
        width: 300px;
        height: 300px;

        & div.picture-container {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;

          & img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    & div.who-text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & p {
        padding-left: 20px;
        text-align: justify;
      }
    }
  }
}

div.parallax-container {
  position: relative;
  width: @width;
  margin: auto;
  height: 400px;
  overflow: hidden;

  & div.parallax {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    & img.parallax-image {
      opacity: 0;
      position: absolute;
      left: 50%;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      transform: translateX(-50%);
    }
  }

  & div.parallax-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .3);

    & span {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 20px 24px;
      background-color: rgba(255, 255, 255, .7);
      font-size: 2em;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
}

div.gallery-container {
  width: @width;
  margin: 60px auto;

  & div.gallery-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 2vmin;
    margin: auto;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    & div.picture-container {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-column: span 1;
      width: 100%;
      height: 100px;

      & img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        transform: scale(1);
        transition: all .25s;
        z-index: 1;

        &:hover {
          z-index: 2;
          transform: scale(1.5);
        }
      }
    }
  }
}
</style>
